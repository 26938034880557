import { h, ref, Teleport, Comment, computed, inject, provide, resolveComponent, onMounted } from 'vue';
import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';
import { dataGridEditorCellControlKey } from 'o365.modules.vue.injectionKeys.js';
import utils from 'o365.modules.utils.js';
import { AriaIndexParser } from 'o365.vue.composables.DataGridNavigation.ts';
// TODO: Remove after checking its safe
import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor } from 'o365.vue.components.inputEditors.jsx';
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
const InputEditors = { OTextEditor, ONumberEditor, OBitEditor, ODateEditor };

export default {
    name: 'ODataGridCellEditor',
    props: ['data', 'dataColumns', 'activeCell', 'gridContainer', 'dataGridControl'],
    setup(props, context) {
        provide('is-in-grid-cell', true);
        const resolveComponentFromApp = inject('resolveComponent', null);

        //--- PopUp State ---
        const expandedPopup = ref(false);

        function activatePopupMode() {
            expandedPopup.value = true;
        }

        function deactivatePopupMode() {
            expandedPopup.value = false;

        }

        provide(dataGridEditorCellControlKey, {
            expandedPopup,
            activatePopupMode,
            deactivatePopupMode
        });
        //-------------------

        const colIndex = computed(() => {
            return parseInt(props.activeCell.split('-')[0]);
        });
        const rowIndex = computed(() => {
            return parseInt(props.activeCell.split('-')[1]);
        });

        const column = computed(() => {
            return props.dataColumns.columns[colIndex.value];
        });

        const row = computed(() => {
            return props.data[rowIndex.value] ?? {};
        })

        const editorClass = computed(() => {
            const classes = [{
                'o365-editor-popup': expandedPopup.value,
                'p-2': expandedPopup.value,
                'rounded-0': expandedPopup.value,
                'card': expandedPopup.value,
                'shadow': expandedPopup.value,
                'o365-cell-range-single-cell': !expandedPopup.value
            }];
            if (column.value?.cellClass) {
                classes.push(column.value.cellClass);
            }
            return classes;
        });

        const style = computed(() => {
            const rowIndex = parseInt(props.activeCell.split('-')[1]);

            //const pos = rowIndex * props.rowHeight;
            const pos = props.dataGridControl.virtualScrollApi.getPosByIndex(rowIndex);
            const height = props.dataGridControl.virtualScrollApi.getRowHeightByIndex(rowIndex);

            return {
                'min-width': expandedPopup.value ? '400px' : null,
                'position': 'absolute',
                'width': column.value.width + column.value.widthAdjustment + 'px',
                'left': column.value.left + 'px',
                'height': expandedPopup.value ? 'auto' : `${height}px`,
                'transform': `translateY(${pos}px)`,
            }
        });

        const editor = computed(() => {
            deactivatePopupMode();
            if (column.value.cellEditorSlot) {
                return { node: column.value.cellEditorSlot, isSlot: true };
            } else {
                if (typeof column.value.cellEditor === 'string') {
                    let editor = InputEditors[column.value.cellEditor];
                    let node = editor ?? resolveComponentFromApp(column.value.cellEditor) ?? resolveComponent(column.value.cellEditor);
                    return { node: node, isSlot: false };
                } else {
                    return { node: column.value.cellEditor, isSlot: false };
                }
            }
        });

        function getContainer(pinned) {
            if (pinned) {
                return props.gridContainer.querySelector('.o365-body-left-pinned-cols');
            } else {
                return props.gridContainer.querySelector('.o365-body-center-cols-container');
            }
        }

        const editorProps = computed(() => {
            if (typeof editor.value === 'object' && editor.value?.node?.props) {
                if (Array.isArray(editor.value.node.props)) {
                    return editor.value.node.props;
                } else {
                    return Object.keys(editor.value.node.props);
                }
            } else {
                return [];
            }
        });

        const renderWithErrorBoundry = (renderFn, props) => {
            try {
                return renderFn(props);
            } catch (ex) {
                return h('span', {
                    class: 'text-danger',
                    title: 'An error has occurred when trying to render this cell',
                }, [
                    h('i', { class: 'bi bi-exclamation-triangle-fill me-1' }),
                    'Render Error'
                ]);
            }
        }

        return () => h(Teleport, { to: getContainer(column.value.pinned) }, h('div', { 'class': ['o365-body-cell o365-editor-cell', editorClass.value], 'style': style.value },
            editor.value.isSlot
                ? renderWithErrorBoundry(editor.value.node, {
                    modelValue: row.value,
                    row: row.value,
                    column: column.value,
                    class: column.value.editorClass,
                    ref: 'editorRef',
                    ...(column.value.cellEditorParams ?? {})
                })
                // ? h(editor.value.node, {
                //     modelValue: row.value,
                //     row: row.value,
                //     column: column.value,
                //     class: column.value.editorClass,
                //     ref: 'editorRef',
                //     ...(column.value.cellEditorParams ?? {})
                // })
                : h(editor.value.node, {
                    modelValue: row.value[column.value.field],
                    'onUpdate:modelValue': (value) => { row.value[column.value.field] = value; },
                    row: editorProps.value.includes('row') ? row.value : undefined,
                    column: editorProps.value.includes('column') ? column.value : undefined,
                    class: column.value.editorClass,
                    ref: 'editorRef',
                    ...(column.value.cellEditorParams ?? {})
                })
        ));
    }
};

export const ODataGridNewRecordCellEditor = {
    name: 'ODataGridNewRecordCellEditor',
    props: {
        row: null,
        column: null,
        current: Boolean,
        isLast: Boolean,
        utils: null,
        rowHeight: [String, Number],
        resolveComponentFromApp: Function
    },
    setup(props, context) {
        const RowErrorDropdown = useAsyncComponent('o365.vue.components.DataGrid.ErrorDropdown.vue');
        provide('is-in-grid-cell', true);
        //--- PopUp State ---
        const expandedPopup = ref(false);

        function activatePopupMode() {
            expandedPopup.value = true;
        }

        function deactivatePopupMode() {
            expandedPopup.value = false;

        }

        provide(dataGridEditorCellControlKey, {
            expandedPopup,
            activatePopupMode,
            deactivatePopupMode
        });
        //-------------------

        function handleBatchFocus(e) {
            e.stopPropagation();
            const target = e.target;
            e.target.classList.add('text-primary');
            window.addEventListener('click', () => {
                target.classList.remove('text-primary');
            }, true);
        }

        const editor = computed(() => {
            deactivatePopupMode();
            if (props.column.cellEditorSlot) {
                return { node: props.column.cellEditorSlot, isSlot: true };
            } else {
                if (typeof props.column.cellEditor === 'string') {
                    let editor = InputEditors[props.column.cellEditor];
                    let node = editor ?? props.resolveComponentFromApp(props.column.cellEditor) ?? resolveComponent(props.column.cellEditor);
                    return { node: node, isSlot: false };
                } else {
                    return { node: props.column.cellEditor, isSlot: false };
                }
            }
        });

        const editorProps = computed(() => {
            if (typeof editor.value === 'object' && editor.value?.node?.props) {
                if (Array.isArray(editor.value.node.props)) {
                    return editor.value.node.props;
                } else {
                    return Object.keys(editor.value.node.props);
                }
            } else {
                return [];
            }
        });

        const conditionalProps = computed(() => {
            const obj = {};
            if (editorProps.value) {
                if (editorProps.value.includes('row')) {
                    obj.row = props.row;
                }
                if (editorProps.value.includes('column')) {
                    obj.column = props.column;
                }
            }
            return obj;
        });

        const renderError = () => h('span', {
            class: 'text-danger',
            title: 'An error has occurred when trying to render this cell',
            onClick: () => { capturedError.value = null; }
        }, [
            h('i', { class: 'bi bi-exclamation-triangle-fill me-1' }),
            'Render Error'
        ]);

        const renderWithErrorBoundry = (renderFn, props) => {
            try {
                return <renderFn {...props}/>
                // return renderFn(props);
            } catch (ex) {
                console.error(`Error encountered when trying to render column: ${props.column?.colId}\n`, ex);
                return renderError();
            }
        }

        const [ capturedError ] = useErrorCapture({
            consoleMessagee: `Error encountered when trying to render column: ${props.column?.colId}`
        });

        const isEditable = computed(() => {
            if (typeof props.column.editable === 'function') {
                return props.column.editable(props.row);
            } else {
                return props.column.editable;
            }
        });

        return () => {
            // System columns rendering
            switch (props.column.colId ?? props.column.field) {
                case 'o365_Action':
                    return props.row.hasChanges
                    ? <OActionCancel dataObject={props.utils.dataObject} row={props.row} style={{ 'display': props.row.current && !props.isLast ? '' : 'none' }} >
                        <i class='bi bi-arrow-counterclockwise'></i>
                    </OActionCancel>
                    : <OActionDelete confirm={false} dataObject={props.utils.dataObject} row={props.row} style={{ 'display': props.row.current && !props.isLast ? '' : 'none' }} softDelete={false}>
                        <i class='bi bi-x-lg'></i>
                    </OActionDelete>;
                    return [
                        <OActionDelete confirm={props.column.cellRendererParams?.deleteConfirm} dataObject={props.utils.dataObject} row={props.row} style={{ 'display': props.row.current && !props.row.hasChanges && !props.row.error && !props.isLast ? '' : 'none' }}>
                            <i class='bi bi-x-lg'></i>
                        </OActionDelete>,
                        <OActionCancel dataObject={props.utils.dataObject} row={props.row} style={{ 'display': props.row.current && props.row.hasChanges && !props.isLast ? '' : 'none' }}>{
                            () => <i class='bi bi-arrow-counterclockwise'></i>
                        }</OActionCancel>
                    ];
                case 'o365_MultiSelect':
                    /** @type {(e: MouseEvent) => void} */
                    const handleOnClick = (e) => {
                        props.row.isSelected = e.target.checked;
                    };
                    return <input type="checkbox" disabled={props.row.isNewRecord} class="form-check-input p-2" onClick={handleOnClick} checked={props.row.isSelected}></input>
                    // return <Comment>v-if</Comment>;
                case 'o365_System':
                    if (props.row.error) {
                        return <RowErrorDropdown row={props.row}></RowErrorDropdown>
                        // const error = typeof props.row.error === 'string'
                        //     ? props.row.error
                        //     : props.row.error.message ?? props.row.error.error ?? props.row.error.Message ?? props.row.error;
                        // return <i class="text-danger bi bi-exclamation-triangle-fill" title={error} role="button" onClick={(e) => { e.stopPropagation(); props.row.cancelChanges() }}></i>
                    } else if (props.isLast) {
                        return <i class="bi bi-asterisk" onClick={handleBatchFocus}></i>
                    } else if (props.row.isSaving) {
                        return <div class="spinner-border spinner-border-sm mt-1" role="status"></div>
                    } else if (props.row.current && props.row.hasChanges) {
                        return <i class="bi bi-save text-primary" onClick={(e) => { e.stopPropagation(); props.row.disableSaving = false; props.row.save() }} title="Save" role="button"></i>
                    } else if (props.row.current) {
                        return <i class="bi bi-caret-right-fill"></i>
                    } else {
                        return <Comment>v-if</Comment>;
                    }
                default:
                    if (!isEditable.value && props.column.cellRenderSlot) {
                        const Renderer = props.column.cellRenderSlot;
                        return <Renderer row={props.row} column={props.column}></Renderer>
                    } else if (!isEditable.value && props.column.cellRenderer) {
                        let Renderer = props.column.cellRenderer;
                        if (typeof Renderer === 'string') {
                            Renderer = resolveComponent(Renderer);
                        }
                        const rowProp = Renderer.props?.includes('row') ? props.row.item : null;
                        return <Renderer
                            v-model={props.row.item}
                            column={props.column}
                            row={rowProp}
                        ></Renderer>
                    } else if (!isEditable.value) { return utils.format(props.row[props.column.field], props.column); }
                    break;
            }

            const renderEditor = () => {
                const Editor = editor.value.node;
                return capturedError.value ?
                    renderError()
                    : editor.value.isSlot
                        ? renderWithErrorBoundry(Editor, {
                            'modelValue': props.row,
                            'row': props.row,
                            'column': props.column,
                            'class': props.column.editorClass,
                            'ref': 'editorRef',
                            ...(props.column.cellEditorParams ?? {})
                        })
                        // ? <Editor
                        //     modelValue={props.row}
                        //     row={props.row}
                        //     column={props.column}
                        //     class={props.column.editorClass}
                        //     ref="editorRef"
                        //     {...(props.column.cellEditorParams ?? {})} />
                        : <Editor
                            modelValue={props.row[props.column.field]}
                            onUpdate:modelValue={(value) => {
                                if (value === null && typeof props.row[props.column.field] === 'undefined') { return; }
                                props.row[props.column.field] = value;
                            }}
                            // row={props.row}
                            // column={props.column}
                            // row={Editor.props?.includes?.call('row') ? props.row : Editor.props?.hasOwnProperty('row') ? props.row : null }
                            // column={Editor.props?.includes?.call('column') ? props.column : Editor.props?.hasOwnProperty('column') ? props.column : null }
                            // row={editorProps.value?.includes('row') ? props.row : null}
                            // column={editorProps.value?.includes('column') ? props.column : null}
                            class={props.column.editorClass}
                            ref="editorRef"
                            // {...(props.column.cellEditorParams ?? {})} />;
                            {...{ ...conditionalProps.value, ...(props.column.cellEditorParams ?? {}) }} />;
            };

            const renderPopup = () => {
                return <EditorPopup ref="popupRef" width={props.column.width + props.column.widthAdjustment} value={props.row[props.column.field]} rowHeight={props.rowHeight}>{renderEditor()}</EditorPopup>;
            };

            return expandedPopup.value
                ? renderPopup()
                : renderEditor();
        };
    }
};

const EditorPopup = {
    name: 'EditorPopup',
    props: {
        value: null,
        width: Number,
        openOnMount: Boolean,
        rowHeight: [Number,String],
    },
    setup(props, context) {

        const dropdown = ref(null);
        const popupButtonRef = ref(null);
        const cellRef = computed(() => {
            return popupButtonRef.value?.parentElement;
        });

        const computedStyle = computed(() => {
            return {
                'min-width': '300px',
                'width': props.width + 'px'
            };
        });

        const popperOptions = [
            {
                name: 'flip',
                enabled: false
            },
            {
                name: 'offset',
                options: {
                    offset: [0, -1*(+props.rowHeight)],
                },
            },
        ];

        function activateEditor() {
            dropdown.value.open();
        }

        function focusEditor() {
            let element = dropdown.value.container.querySelector('textarea') ?? dropdown.value.container.querySelector('input');
            if (element) { element.focus(); }
        }

        function closePopup() {
            dropdown.value.close().then(() => {
                popupButtonRef.value?.focus();
            });
        }

        // TODO: Need better way to open popups through keyboard, should maybe be kept in gird navigation composable
        const tempKeydownHandler = (e) => {
            if (e.key === 'F2') {
                e.stopPropagation();
                e.preventDefault();
                activateEditor();
            }
        };

        const onKeydown = (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                closePopup();
                if (!props.openOnMount) {
                    return;
                }
            } else if (e.key === 'Tab') {
                e.preventDefault();
            }

            popupButtonRef.value.dispatchEvent(new KeyboardEvent('keydown', {
                bubbles: true,
                cancelable: true,
                key: e.key,
                code: e.code,
                shiftKey: e.shiftKey,
                ctrlKey: e.ctrlKey,
                altKey: e.altKey,
            }));

            if (e.key === 'Escape') {
                closePopup();
            }
        };

        const dropdownSlots = {
            default: (scope) => {
                return <div ref={el => popupButtonRef.value = el}
                    class={['w-100 h-100 o365-editor-popup-button text-truncate', { 'text-muted text-center': !props.value }]}
                    tabindex="-1"
                    onClick={scope.open}
                    onKeydown={tempKeydownHandler}
                    style="outline: none;"
                    title={props.value ?? 'Click to edit'}>
                    {props.value}
                </div>
            },
            dropdown: (scope) => {
                return <div ref={scope.container}
                    class="card shadow rounded-0 p-2 o365-editor-popup" style={computedStyle.value} onKeydown={onKeydown}>
                    {context.slots.default()}
                </div>
            }
        };

        if (props.openOnMount) {
            onMounted(() => {
                activateEditor();
            });
        }

        context.expose({ activateEditor });

        return () => {
            const Dropdown = resolveComponent('ODropdown');
            return <Dropdown ref={x => dropdown.value = x}
                v-slots={dropdownSlots}
                targetRef={cellRef.value}
                virtual popperOptions={popperOptions}
                onOnopen={focusEditor} />
        };
    }
};


//--- WIP ---

export const WIP = {
    name: 'ODataGridCellEditor',
    props: ['data', 'dataColumns', 'activeCell', 'gridContainer'],
    setup(props, context) {

        const resolveComponentFromApp = inject('resolveComponent', null);

        //--- PopUp State ---
        const expandedPopup = ref(false);

        function activatePopupMode() {
            expandedPopup.value = true;
        }

        function deactivatePopupMode() {
            expandedPopup.value = false;

        }

        provide(dataGridEditorCellControlKey, {
            expandedPopup,
            activatePopupMode,
            deactivatePopupMode
        });
        //-------------------

        const colIndex = computed(() => {
            return parseInt(props.activeCell.split('-')[0]);
        });
        const rowIndex = computed(() => {
            return parseInt(props.activeCell.split('-')[1]);
        });

        const column = computed(() => {
            return props.dataColumns.columns[colIndex.value];
        });

        const row = computed(() => {
            return props.data[rowIndex.value] ?? {};
        })

        const editorClass = computed(() => {
            return {
                'rounded-0': expandedPopup.value,
                'o365-cell-range-single-cell': !expandedPopup.value
            };
        });

        const style = computed(() => {
            const rowIndex = parseInt(props.activeCell.split('-')[1]);

            const pos = rowIndex * 34;

            return {
                'position': 'absolute',
                'width': column.value.width + column.value.widthAdjustment + 'px',
                'left': column.value.left + 'px',
                'height': '34px',
                'transform': `translateY(${pos}px)`,
            }
        });

        const editor = computed(() => {
            deactivatePopupMode();
            if (column.value.cellEditorSlot) {
                return { node: column.value.cellEditorSlot, isSlot: true };
            } else {
                if (typeof column.value.cellEditor === 'string') {
                    let editor = InputEditors[column.value.cellEditor];
                    let node = editor ?? resolveComponentFromApp(column.value.cellEditor) ?? resolveComponent(column.value.cellEditor);
                    return { node: node, isSlot: false };
                } else {
                    return { node: column.value.cellEditor, isSlot: false };
                }
            }
        });

        function getContainer(pinned) {
            if (pinned) {
                return props.gridContainer.querySelector('.o365-body-left-pinned-cols');
            } else {
                return props.gridContainer.querySelector('.o365-body-center-cols-container');
            }
        }

        const editorProps = computed(() => {
            if (typeof editor.value === 'object' && editor.value?.node?.props) {
                if (Array.isArray(editor.value.node.props)) {
                    return editor.value.node.props;
                } else {
                    return Object.keys(editor.value.node.props);
                }
            } else {
                return [];
            }
        });

        const renderEditor = () => {
            return editor.value.isSlot
                ? h(editor.value.node, {
                    modelValue: row.value,
                    row: row.value,
                    column: column.value,
                    class: column.value.editorClass,
                    ref: 'editorRef',
                    ...(column.value.cellEditorParams ?? {})
                })
                : h(editor.value.node, {
                    modelValue: row.value[column.value.field],
                    'onUpdate:modelValue': (value) => { row.value[column.value.field] = value; },
                    row: editorProps.value.includes('row') ? row.value : null,
                    column: editorProps.value.includes('column') ? column.value : null,
                    class: column.value.editorClass,
                    ref: 'editorRef',
                    ...(column.value.cellEditorParams ?? {})
                })
        };

        const renderPopup = () => {
            return <EditorPopup ref="popupRef" width={column.value.width + column.value.widthAdjustment} value={row.value[column.value.field]} openOnMount>{renderEditor()}</EditorPopup>;
        };
        /**
         *                 'o365-editor-popup': expandedPopup.value,
                        'p-2': expandedPopup.value,
                        'rounded-0': expandedPopup.value,
                        'card': expandedPopup.value,
                        'shadow': expandedPopup.value,
         */
        const renderPopupContainer = () => {
            return h(Teleport, { to: 'body' },
                h('div', { class: 'o365-editor-popup p=2 rounded-0 card shadow' }, renderEditor()));
        };

        return () => h(Teleport, { to: getContainer(column.value.pinned) }, h('div', { 'class': ['o365-body-cell o365-editor-cell', editorClass.value], 'style': style.value },
            expandedPopup.value
                ? renderPopup()
                : renderEditor()
        ));
    }
};
